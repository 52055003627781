header.alt {
	#global-header {
		background-color: #183251;
	}
}

#officers-panel {
	display: none;
}

#masthead {
	position: relative;
	margin: 0;
	padding: 0;
	background: #0a4b90 url(/assets/img/bg-masthead-01.jpg) center center no-repeat !important;
}

.slider-panel {
	background-position: center center !important;
	background-repeat: no-repeat !important;

	filter: progid:DXImageTransform.Microsoft.AlphaImageLoader( src='arrow-big-right.png', sizingMethod='scale');
    -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader( src='arrow-big-right.png', sizingMethod='scale')";

		&.slide-one {
			background: #0a4b90 url(/assets/img/bg-masthead-01.jpg) center center no-repeat;
			filter: progid:DXImageTransform.Microsoft.AlphaImageLoader( src='/assets/img/bg-masthead-01.jpg', sizingMethod='scale');
    		-ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader( src='/assets/img/bg-masthead-01.jpg', sizingMethod='scale')";
		}
		&.slide-two {
			background: #0a4b90 url(/assets/img/bg-masthead-02.jpg) center center no-repeat;
			filter: progid:DXImageTransform.Microsoft.AlphaImageLoader( src='/assets/img/bg-masthead-02.jpg', sizingMethod='scale');
    		-ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader( src='/assets/img/bg-masthead-02.jpg', sizingMethod='scale')";
		}
		&.slide-three {
			background: #0a4b90 url(/assets/img/bg-masthead-03.jpg) center center no-repeat;
			filter: progid:DXImageTransform.Microsoft.AlphaImageLoader( src='/assets/img/bg-masthead-03.jpg', sizingMethod='scale');
    		-ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader( src='/assets/img/bg-masthead-03.jpg', sizingMethod='scale')";
		}
		&.slide-four {
			background: #0a4b90 url(/assets/img/bg-masthead-04.jpg) center center no-repeat;
			filter: progid:DXImageTransform.Microsoft.AlphaImageLoader( src='/assets/img/bg-masthead-04.jpg', sizingMethod='scale');
    		-ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader( src='/assets/img/bg-masthead-04.jpg', sizingMethod='scale')";
		}
}

#slider-wrapper {
	background-color: #0b3553;
}